import React, {useContext} from 'react';
import { authRedirect, getUserInfo } from '@buzzfeed/bf-utils/lib/user';
import BuzzContext from '../../contexts/buzz';
import { trackClientInternalLink, trackClientContentAction } from '../../hooks/analytics/client-event-tracking';
import styles from './discussionCtaButton.module.scss';

const DiscussionCtaButton = () => {
  const buzz = useContext(BuzzContext);

  const onButtonClick = () => {
    const commonTrackingData = {
      unit_name: buzz.id,
      unit_type: 'buzz_head',
      item_type: 'button',
    };
    // if user is not logged in, redirect to login page
    if (!getUserInfo()) {
      trackClientInternalLink(buzz, {
        ...commonTrackingData,
        item_name: 'sign_in_to_comment',
        target_content_id: 'sign_in',
        target_content_type: 'auth',
      });
      authRedirect();
    } else {
      // if user is logged in, set focus to comment form
      const commentsWrapper = document.getElementById('comments'); // CommentsWrapper component
      if (commentsWrapper) {
        window.scrollTo({
          top: commentsWrapper.getBoundingClientRect().top + scrollY - 110,
          left: 0,
          behavior: 'smooth'
        });
        const commentsInuput = document.getElementById('comment-form-textarea');
        if (commentsInuput) {
          commentsInuput.focus();
        }
        trackClientContentAction(buzz, {
          ...commonTrackingData,
          item_name: 'comment-cta',
          action_value: 'comment-cta',
          action_type: 'navigate',
        });
      }
    }
  };
  return (
    <button className={styles.ctaButton} onClick={onButtonClick}>Start The Discussion</button>
  );
};

export default DiscussionCtaButton;
