import React, { useContext } from 'react';
import { CaretLeftIcon } from '@buzzfeed/react-components';
import { destinations } from '../../constants';
import BuzzContext from '../../contexts/buzz';
import { useUnitTracking } from '../../hooks/analytics/useUnitTracking';
import styles from './topic-page-link.module.scss';

export const TopicPageLink = ({
  display_name,
  position,
  slug,
}) => {
  const buzz = useContext(BuzzContext);

  const unitEl = useUnitTracking(
    {
      unit_type: position === 'top' ? 'buzz_head' : 'buzz_bottom',
      unit_name: buzz.id,
      item_type: 'text',
      item_name: slug, // Topic page slug
      target_content_type: 'feed',
      target_content_id: 8,
    }
  );  

  // currently assumed only displays on BF discuss pages.  This would have to 
  //  update if that changes
  const url = `${destinations['buzzfeed']?.base_url}/topic/${slug}`;

  return (
    <div className={`${styles.topicPageLink} ${styles[position]}`} >
      <a ref={unitEl} href={url}>
        <CaretLeftIcon className={styles.caret} />
        <div>Go Back to<span className={styles.pageName}>{display_name}</span></div>
      </a>
    </div>
    
  );
};

export default TopicPageLink;
