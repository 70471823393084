import { ErrorBoundary, useBreakpoint } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AdUnit from '../../Ads/AdUnit';
import CommentsWrapper from '../../CommentsWrapper';
import ContentBottom from '../../ContentBottom';
import DestinationContext from '../../../contexts/destination';
import { FlameIcon } from '../../Svgs/FlameIcon';
import { NativeShareIcon } from '../../Svgs/NativeShareIcon';
import NewsletterSignup from '../../NewsletterSignup/lazy';
import { SpeechBuble } from '../../Svgs/SpeechBuble';
import { BottomRecircList, RelatedRecircList } from '../../RecircList/variants';
import Sidebar from '../../Sidebar';
import ShareBar from '../../ShareBar';
import TopicPageLink from '../../TopicPageLink';
import DiscussionCtaButton from '../../DiscussionCtaButton';
import layoutStyles from '../post.module.scss';

function DiscussionPost({ buzz }) {
  const ref = useRef(null);
  const { destination } = useContext(DestinationContext);
  const isMobile = useBreakpoint('(max-width:500px)'); // page level break is based on bf_header_ui break
  const [commentCount, setCommentCount] = useState(null);
  const [onShareClick, setOnShareClick] = useState();
  const showHeadline = false; // toDo: find a way to get data (title, link...) for the healine

  // The longform custom header will override the title.
  const { title } = buzz.longform_custom_header ?? buzz;

  let topicPage = buzz.relatedTopicFeeds?.topicPageTagFeeds?.find((feed) =>
      feed.edition === buzz.country_code && feed.domain === 'bf') || null;
  if (!topicPage && buzz.relatedTopicFeeds?.remainingTagFeeds?.length) {
    topicPage = buzz.relatedTopicFeeds.remainingTagFeeds.find((feed) =>
      feed.edition === buzz.country_code && feed.domain === 'bf') || null;
  }

  useEffect(() => {
    const url = window.location.href;
    if (navigator?.share && navigator.canShare({ url })) {
      const share = () => {
        navigator.share({
          title,
          url: window.location.href
        });
      };
      setOnShareClick(() => share);
    }
  }, []);

  return (
    <main
      id="buzz-content"
      className={`${layoutStyles.main} ${layoutStyles[destination]} ${layoutStyles.commentsMain} embed-content`}
      ref={ref}
    >
      <div
        className={`${layoutStyles.article} ${layoutStyles.default} ${layoutStyles[destination]} embed-post`}
      >
        <div className={layoutStyles.content}>
          {topicPage &&
            <TopicPageLink
              display_name={topicPage.display_name}
              position="top"
              slug={topicPage.slug}
            />}
          {showHeadline && <div className={layoutStyles.headlineContainer}>
            Shared on <FlameIcon className={layoutStyles.flameIcon} title="Harry Potter"/><a href="#" className={layoutStyles.flameTitle}>Harry Potter</a>
          </div>}
          <article className={layoutStyles.discussionTitleWrapper}>
            <h1 dangerouslySetInnerHTML={{ __html: title }}>
            </h1>
            <p className={layoutStyles.commentsAndShare}>
              {commentCount ? (
                <><SpeechBuble className={layoutStyles.commentsBubble} /><span className={layoutStyles.numberOfComments}>{commentCount}</span></>
              ) : (
                <DiscussionCtaButton />
              )}
              {onShareClick && <button onClick={onShareClick} className={layoutStyles.nativeShare}><NativeShareIcon title="Share" className={layoutStyles.nativeShareIcon}/></button>}
            </p>
          </article>

          <ErrorBoundary onError={captureException}>
            <div className={layoutStyles.discussionCommentsWrapper} >
              <CommentsWrapper
                buzz={buzz}
                commentCount={commentCount}
                onCommentsLoaded={setCommentCount}
                firstPage={9}
                repliesFirstPage={2}
              />
            </div>
            {topicPage &&
              <TopicPageLink
                display_name={topicPage.display_name}
                position="bottom"
                slug={topicPage.slug}
              />}
            <div className={layoutStyles.discussionShare}>
              <h2>Share this discussion</h2>
              <ShareBar
                buzz={buzz}
                type="pagelevel"
                position="top_share_bar"
                className={layoutStyles.shareBar}
              />
            </div>
            <AdUnit
              type="promo-inline1"
              pixiedust={{
                unit_type: 'buzz_bottom',
                unit_name: buzz.id,
                position_in_unit: 0,
              }}
            />
            <ContentBottom buzz={buzz} />
            <RelatedRecircList />
            <NewsletterSignup />
            {isMobile && (
              <AdUnit
                type="bigstory"
                pixiedust={{
                  unit_type: 'buzz_bottom',
                  unit_name: buzz.id,
                  position_in_unit: 1,
                }}
              />
            )}
          </ErrorBoundary>
        </div>
        <Sidebar
          pixiedust={{
            unit_type: 'sidebar',
            unit_name: 'right',
            position_in_unit: 0,
          }}
        />
      </div>
      <ErrorBoundary onError={captureException}>
        <BottomRecircList className={layoutStyles.discussionBottomUnit} />
      </ErrorBoundary>
    </main>
  );
}

DiscussionPost.propTypes = {
  buzz: PropTypes.object.isRequired,
  badges: PropTypes.array,
};

export default DiscussionPost;
