import { FEATURE_FLAG as CONSENT_FEATURE_FLAG } from '@buzzfeed/consent';
import { isNews } from '../utils/isNews';
import { isOrHasQuiz } from '../utils/isOrHasQuiz';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';

const featureFlags = [
  {
    name: 'TimeSpentRO_4',
    variations: ['on', 'control'],
    isEligible: () => true,
  },
  {
    name: 'ads_mweb_quiz_promo-inline1',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      (buzz.isQuiz ||
        buzz.isBFPQuiz ||
        buzz.sub_buzzes?.find((sb) => sb.form === 'quiz')) &&
      !window.matchMedia('(min-width: 40rem)').matches, // xs
  },
  {
    name: 'PAR-where-to-watch-release',
    variations: ['control', 'on'],
    isEligible: () => true,
  },
  {
    name: 'COMMERCE-1437_gg_category_bpage',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'shoppybot_bpage_promo',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'ads_high_density_bpage_non_quiz',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      !(
        buzz.isQuiz ||
        buzz.isShopping ||
        buzz.isBFPQuiz ||
        buzz.sub_buzzes?.find((sb) => sb.form === 'quiz')
      ) &&
      buzz.classification.edition !== 'Japan' &&
      !buzz.isAd,
  },
  {
    name: 'ads_high_density_bpage_quiz',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      (buzz.isQuiz ||
        buzz.isBFPQuiz ||
        buzz.sub_buzzes?.find((sb) => sb.form === 'quiz')) &&
      buzz.classification.edition !== 'Japan' &&
      !buzz.isAd,
  },
  {
    name: 'ads_destroy_ads_on_scroll_mobile',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      buzz.classification.edition !== 'Japan' &&
      !buzz.isAd &&
      !window.matchMedia('(min-width: 40rem)').matches, // xs,
  },
  {
    name: 'ads_shopping_density_test',
    variations: ['control', 'variant1', 'variant2'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isShopping,
  },
  {
    name: 'ads_wunderkind_uk',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      (getUserGeoCountry().toLowerCase() === 'gb' ||
        getUserGeoCountry().toLowerCase() === 'ie') &&
      !isNews(buzz) &&
      !buzz.isAd,
  },
  {
    name: 'ads_wunderkind_news',
    variations: ['control', 'on'],
    isEligible: (buzz) => isNews(buzz) && !buzz.isAd,
  },
  {
    name: 'RT-1041-Outbrain-on-BFN',
    variations: ['control', 'on'],
    isEligible: (buzz) => isNews(buzz),
  },
  {
    name: 'RT-1042-Admiral-script-on-BFDC',
    variations: ['control', 'on'],
    isEligible: (buzz) => !isNews(buzz),
  },
  {
    name: 'RT-1042-Admiral-script-on-BFN',
    variations: ['control', 'on'],
    isEligible: (buzz) => isNews(buzz),
  },
  {
    name: 'RT-1328-Wunderkind-on-desktop',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      getUserGeoCountry().toLowerCase() === 'us' &&
      !buzz.isShopping &&
      !isNews(buzz) &&
      window.matchMedia('(min-width: 40rem)').matches,
  },
  {
    name: 'RT-1362-amazon-nca-beta',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.id === '7675364',
  },
  {
    name: 'prime-day-2024-bpage-recirc',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.isUS && !buzz.isAd,
  },
  {
    name: 'ads_amazon_nca',
    variations: ['control', 'on'],
    isEligible: () => true,
  },
  {
    name: 'ads_pre_cyber_week',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.isUS && !buzz.isAd,
  },
  {
    name: 'ads_cyber_week',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.isUS && !buzz.isAd,
  },
  {
    name: 'taboola_apac',
    variations: ['control', 'on'],
    isEligible: (buzz) => buzz.classification.edition !== 'Japan',
  },
  {
    name: 'RT-1522-sidebar-15s-refresh',
    variations: ['control', 'on'],
    isEligible: () => window.matchMedia('(min-width: 40rem)').matches,
  },
];

const abTests = [
  {
    name: 'commerce_ad_affiliate',
    variations: ['control', 'top', 'buzz'],
    storeResponse: true,
    isEligible: (buzz) => !buzz.isUS,
  },
  {
    name: 'commerce_ad_recirc',
    variations: ['control', 'top', 'buzz'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isUS,
  },
  {
    name: 'newsletter-popup',
    variations: ['control', 'on'],
    isEligible: (buzz) => {
      if (!buzz.isUS) {
        return false;
      }

      if (buzz.tags.includes('amazon-pets')) {
        return true;
      }

      const allSections = buzz.all_classifications
        ? buzz.all_classifications.sections
        : [];
      const allowedSections = ['Shopping'];
      return allSections.some((section) => allowedSections.includes(section));
    },
  },
  {
    name: 'newsletter-primeday',
    variations: ['control', 'on'],
    isEligible: (buzz) => {
      if (!buzz.isUS || buzz.tags.includes('amazon-pets')) {
        return false;
      }
      const allSections = buzz.all_classifications
        ? buzz.all_classifications.sections
        : [];
      const lifestyleSections = [
        'As/Is',
        'Food',
        'Goodful',
        'Nifty',
        'Parents',
        'Shopping',
      ];
      return allSections.some((section) => lifestyleSections.includes(section));
    },
  },
  {
    name: 'shoppybot-popup',
    variations: ['control', 'on'],
    isEligible: (buzz) => {
      if (!buzz.isUS) {
        return false;
      }
      const allSections = buzz.all_classifications
        ? buzz.all_classifications.sections
        : [];
      const lifestyleSections = [
        'As/Is',
        'Food',
        'Goodful',
        'Nifty',
        'Parents',
        'Shopping',
      ];
      return allSections.some((section) => lifestyleSections.includes(section));
    },
  },
  {
    name: 'shoppy-bpage-test',
    variations: ['control', 'banner', 'chat'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'SITE-7528-TPAU_with_images',
    variations: ['control', 'text', 'image'],
    isEligible: () => true,
    storeResponse: true,
  },
  {
    name: 'PAR-436-etsy-tpau-ranking',
    variations: ['control', 'recsys'],
    isEligible: () => true,
  },
  {
    name: 'COMMERCE-1100_related_products_unit',
    variations: ['control', 'product_grid', 'product_carousel'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isShopping && buzz.isUS,
  },
  {
    name: 'ml-3019-related-content-nomic',
    variations: ['control', 'variant_1', 'variant_2'],
    isEligible: (buzz) => buzz.isUS,
  },
  {
    name: 'bfn-taboola-placement',
    variations: ['control', 'on'],
    storeResponse: true,
    isEligible: (buzz) => isNews(buzz),
  },
  {
    name: 'RT-583-amazon-recommendation-abtest',
    variations: ['control', 'on'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isUS,
  },
  {
    name: 'ri_plushie_filter_login',
    variations: ['control', 'variant'],
    isEligible: (buzz) =>
      buzz.isUS && getUserGeoCountry().toLowerCase() === 'us',
  },
  {
    name: 'RT-1257-Wunderkind_desktop_only',
    variations: ['control', 'no_wunderkind'],
    isEligible: (buzz) =>
      getUserGeoCountry().toLowerCase() === 'us' &&
      !buzz.isShopping &&
      !isNews(buzz) &&
      window.matchMedia('(min-width: 40rem)').matches,
  },
  {
    name: 'RT-946-shopping-recirc-bfn',
    variations: ['control', 'var1'],
    isEligible: (buzz) => isNews(buzz),
  },
  {
    name: 'ads_delay_fetch',
    variations: ['control', 'var1', 'var2', 'var3'],
    isEligible: (buzz) =>
      buzz.isUS &&
      getUserGeoCountry().toLowerCase() === 'us' &&
      !window.matchMedia('(min-width: 40rem)').matches, // xs,
  },
  {
    name: 'RT-947-remove-semisticky-bfn',
    variations: ['control', 'on'],
    isEligible: (buzz) =>
      isNews(buzz) && window.matchMedia('(min-width: 40rem)').matches,
  },
  {
    name: 'RT-945-tb-size-bfn-desktop',
    variations: ['control', 'tb_728x90'],
    isEligible: (buzz) =>
      isNews(buzz) && window.matchMedia('(min-width: 40rem)').matches,
  },
  {
    name: 'RT-1150-ButtonPostTap-abtest',
    variations: ['control', 'no_button_wrap'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isUS && buzz.destination_name === 'buzzfeed',
  },
  {
    name: 'ads_retail_media_network_exposure',
    variations: ['control', 'rmn_off'],
    storeResponse: true,
    isEligible: (buzz) => buzz.isShopping && !buzz.isAd && buzz.isUS,
  },
  {
    name: 'RT-1378-us-quiz-top-sticky',
    variations: ['control', 'top_video_player_on_quiz'],
    storeResponse: true,
    isEligible: (buzz) =>
      isOrHasQuiz(buzz) &&
      !buzz.isAd &&
      buzz.isUS &&
      getUserGeoCountry().toLowerCase() !== 'gb' &&
      getUserGeoCountry().toLowerCase() !== 'ie' &&
      getUserGeoCountry().toLowerCase() !== 'au' &&
      getUserGeoCountry().toLowerCase() !== 'nz',
  },
  {
    name: 'bpage_comments_cta',
    variations: ['control', 'post_comment_only', 'top_comment_only', 'post_comment_and_top_comment'],
    storeResponse: true,
    isEligible: (buzz) => !buzz.isShopping && !buzz.isAd && !isNews(buzz),
  },
];

const combinedFlags = [CONSENT_FEATURE_FLAG, ...featureFlags, ...abTests];
export default combinedFlags;
