import React from 'react';

export const NativeShareIcon = ({ className, title }) => (
  <svg className={className} width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M11.6364 6.90906H12.8182C13.1316 6.90906 13.4322 7.03357 13.6539 7.2552C13.8755 7.47684 14 7.77744 14 8.09088V17.5454C14 17.8589 13.8755 18.1595 13.6539 18.3811C13.4322 18.6027 13.1316 18.7272 12.8182 18.7272H2.18182C1.86838 18.7272 1.56778 18.6027 1.34615 18.3811C1.12451 18.1595 1 17.8589 1 17.5454V8.09088C1 7.77744 1.12451 7.47684 1.34615 7.2552C1.56778 7.03357 1.86838 6.90906 2.18182 6.90906H3.36364" stroke="#5246F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 1V9.27273" stroke="#5246F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.54541 3.95455L7.49996 1L10.4545 3.95455" stroke="#5246F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
